import { createEffect, Show } from "solid-js";
import { useQueryClient } from "@tanstack/solid-query";
import logoImg from "../assets/logo_with_text.svg";
import { appName, session, setTenantId, tenantId } from "./signals";
import { A, useNavigate, useParams } from "@solidjs/router";
import { Text } from "./i18n";
import { supabase } from "./supabaseClient";
import LanguageSelector from "./LanguageSelector";
import type { TenantConfig } from "./TenantTypes";

interface Props {
  showLogo?: boolean;
  config?: TenantConfig;
}

export const NavBar = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  createEffect(() => {
    if (params.tenantId) {
      setTenantId(params.tenantId);
    }
  });

  return (
    <div
      class="ui-navbar items-start"
      classList={{
        "bg-base-100": !props.showLogo,
        "bg-base-100/70": props.showLogo,
      }}
    >
      <div class="ui-navbar-start">
        <Show when={props.showLogo ?? false}>
          <img class="h-[80px] pr-4" src={logoImg} alt={appName()} />
        </Show>
        <div class="ui-navbar-center">
          <Show when={!props.showLogo}>
            <A href={`/${tenantId()}`} end class="ui-btn ui-btn-ghost font-normal">
              <Text message="Home" />
            </A>
          </Show>
          <Show when={tenantId() && props.config?.mode === "editor" && session.user}>
            <A href={`/${tenantId()}/saved-rooms`} class="ui-btn ui-btn-ghost font-normal">
              <Text message="Saved scenes" />
            </A>
          </Show>
        </div>
      </div>
      <div class="ui-navbar-end">
        <LanguageSelector />
        <Show when={tenantId() && !session.user}>
          <A href={`/${tenantId()}/login`} class="ui-btn ui-btn-ghost font-normal">
            <Text message="Log In" />
          </A>
        </Show>
        <Show when={tenantId() && session.user}>
          <A href={`/${tenantId()}/profile`} class="ui-btn ui-btn-ghost font-normal">
            <Text message="My profile" /> |
            <Show when={session.user?.email}>
              <span>{session.user?.email?.split("@")[0]}</span>
            </Show>
            <Show when={!session.user?.email}>
              <span>anon</span>
            </Show>
          </A>
          <button
            type="button"
            class="ui-btn ui-btn-ghost font-normal"
            onClick={async () => {
              await supabase.auth.signOut();
              queryClient.invalidateQueries({ queryKey: ["rooms"] });
              navigate(`/${tenantId()}`);
            }}
          >
            <Text message="Sign Out" />
          </button>
        </Show>
      </div>
    </div>
  );
};
