import { Popover, PopoverButton, PopoverPanel, Transition } from "terracotta";
import { For } from "solid-js";
import { IoLanguageOutline } from "solid-icons/io";
import { useI18n } from "./i18n";
import { language, setLanguage, supportedLanguages, languageLabels } from "./App";

const LanguageSelector = () => {
  const i18n = useI18n();

  return (
    <Popover defaultOpen={false} class="relative">
      {({ isOpen }) => (
        <>
          <PopoverButton class="ui-btn ui-btn-ghost" classList={{ active: isOpen() }} title={i18n.t("Change language")}>
            <IoLanguageOutline size={20} />
          </PopoverButton>
          <Transition
            show={isOpen()}
            enter="transition duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <PopoverPanel unmount={false} class="absolute right-0 top-0 flex w-64 justify-end">
              <div class="bg-panel mt-16 w-full border shadow-md">
                <For each={supportedLanguages}>
                  {(lang) => (
                    <button
                      class="w-3/6 p-3 text-center text-sm"
                      classList={{
                        "btn-lang-choice": lang !== language(),
                        "btn-lang-choice-selected": lang === language(),
                      }}
                      onClick={() => setLanguage(lang)}
                    >
                      {languageLabels.get(lang)}
                    </button>
                  )}
                </For>
              </div>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default LanguageSelector;
