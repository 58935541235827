import { query } from "@solidjs/router";
import { supabase } from "./supabaseClient";

export interface IceServers {
  urls: Array<string>;
  username: string;
  credential: string;
}

export interface ServerInfo {
  iceServers?: IceServers;
  permsToken?: string;
  serverURL: string;
}

export const fetchServerConfig = query(async (): Promise<ServerInfo> => {
  const res = await fetch("/serverConfig.json");
  return res.json();
}, "serverConfig");

export const fetchRoom = async (roomId: string) => {
  const { data, error, status } = await supabase.from("rooms").select("user_id,data").eq("id", roomId).single();

  if (error && status !== 406) {
    console.error("Error fetching room:", error);
    return null;
  }

  return data;
};
