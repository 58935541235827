// import * as dat from "lil-gui";
// import * as THREE from "three";
import "./solid-components/render-app";
// style.css needs to be after AvatarEditor to override :root with css variables
// import "./style.css";

// var SPECTOR = require("spectorjs");

// var spector = new SPECTOR.Spector();
// spector.displayUI();

/**
 * Base
 */
// Debug
// const debugObject = {};
// const gui = new dat.GUI({
//   width: 400,
// });
