import { MetaProvider } from "@solidjs/meta";
import { render } from "solid-js/web";
import { QueryClient, QueryClientProvider } from "@tanstack/solid-query";
// import { SolidQueryDevtools } from "@tanstack/solid-query-devtools";
import App from "./App";

//import posthog from "posthog-js";

//posthog.init("phc_OkKaALhNGug2ggywswRXWpTuqK5Ajg4SoTohAsLmqd9", {
//  api_host: "https://eu.i.posthog.com",
//  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
//});

const queryClient = new QueryClient();

const solidRoot = document.getElementById("root")!;
render(
  () => (
    <MetaProvider>
      <QueryClientProvider client={queryClient}>
        {/* <SolidQueryDevtools /> */}
        <App />
      </QueryClientProvider>
    </MetaProvider>
  ),
  solidRoot
);
