import { query, type RoutePreloadFuncArgs } from "@solidjs/router";
import { fetchServerConfig } from "./Room.data";
import type { SceneElements } from "./SceneElementsTypes";
import type { TenantConfig } from "./TenantTypes";
import { setBaseURLForAssets } from "./signals";
export { absoluteURLForAsset } from "./urlUtils";

function getBaseURL(sceneElements: SceneElements, basePath: string) {
  let baseURL;
  if (basePath.endsWith("/")) basePath = basePath.slice(0, -1);
  if (sceneElements.baseURL) {
    baseURL = sceneElements.baseURL;
    if (baseURL === "../..") {
      baseURL = basePath.split("/").slice(0, -2).join("/");
    }
  } else {
    baseURL = basePath;
  }
  if (!baseURL.endsWith("/")) baseURL += "/";
  return baseURL;
}

const defaultConfig: TenantConfig = { rooms: [], scenes: [], mode: "room" };

export const fetchTenantConfig = query(async (tenantId: string): Promise<TenantConfig> => {
  if (!tenantId) return { ...defaultConfig };
  const response = await fetch(`/tenants/${tenantId}/config.json`);
  if (response.status === 404) {
    throw new Error(`notfound ${response.url}`);
  }
  const results = await response.json();
  return { ...defaultConfig, ...results };
}, "tenantConfig");

export const fetchSceneElements = query(
  async (sceneName: string, tenantId: string | undefined): Promise<SceneElements> => {
    let scene;
    if (tenantId) {
      const scenesJson = await fetchTenantConfig(tenantId);
      scene = scenesJson.scenes.find((s) => s.sceneName === sceneName);
      if (!scene) {
        throw new Error(`notfound ${sceneName}`);
      }
    }

    let basePath;
    if (tenantId && scene) {
      basePath = `/scenes/${tenantId}/scenes/${scene.sceneName}/`;
    } else {
      basePath = `/scenes/${sceneName}/`;
    }
    const url = `${basePath}elements.json`;
    const res = await fetch(url);
    if (res.status === 404) {
      throw new Error(`notfound ${url}`);
    }
    const sceneElements = await res.json();
    const baseURL = getBaseURL(sceneElements, basePath);
    setBaseURLForAssets(baseURL);
    return sceneElements;
  },
  "sceneElements"
);

export function preloadSceneElements({ params }: RoutePreloadFuncArgs) {
  void fetchSceneElements(params.sceneName, params.tenantId);
}

export function preloadSceneElementsAndServerConfig({ params }: RoutePreloadFuncArgs) {
  void fetchSceneElements(params.sceneName, params.tenantId);
  void fetchServerConfig();
}

export function preloadServerConfig({ params }: RoutePreloadFuncArgs) {
  void fetchServerConfig();
}

export function preloadTenantConfig({ params }: RoutePreloadFuncArgs) {
  void fetchTenantConfig(params.tenantId);
}
