import { createAsync, useParams, type RouteSectionProps } from "@solidjs/router";
import { NavBar } from "../NavBar";
import { fetchTenantConfig } from "../Scene.data";

export default function TenantLayout(props: RouteSectionProps) {
  const params = useParams();
  const configQuery = createAsync(() => fetchTenantConfig(params.tenantId));

  return (
    <>
      <NavBar config={configQuery()} />
      {props.children}
    </>
  );
}
