import { createSignal, Show } from "solid-js";
import { Text } from "./i18n";

export const [appNeedsUpdate, setAppNeedsUpdate] = createSignal(false);
export const [appNeedsUpdateOk, setAppNeedsUpdateOk] = createSignal(false);
export const [appNeedsUpdateDismiss, setAppNeedsUpdateDismiss] = createSignal(false);

const AppNeedsUpdate = () => {
  return (
    <Show when={appNeedsUpdate()}>
      <div class="bg-panel absolute bottom-0 left-0 right-0 z-50 flex items-center justify-center space-x-2 p-4">
        <Text message="New version available!" />
        <button
          class="btn btn-primay"
          onClick={() => {
            setAppNeedsUpdateOk(true);
            setAppNeedsUpdate(false);
          }}
        >
          <Text message="Refresh now" />
        </button>
        <button
          class="btn btn-secondary"
          onClick={() => {
            setAppNeedsUpdateDismiss(true);
            setAppNeedsUpdate(false);
          }}
        >
          <Text message="Refresh later" />
        </button>
      </div>
    </Show>
  );
};
export default AppNeedsUpdate;
